<template>
    <div class="hide_mask"></div>
  </template>
  <script>
  import { weChatLogin } from "@/network/thirdPartyLogin/index.js";
  import config from "@/../config/index";
  import { mapMutations, mapActions } from "vuex";
  import { setToken } from "@/utils/auth";
  import { setLanguage } from "@/network/user/index.js";
  import { sessionSave, sessionRead, sessionRemove } from "@/utils/session";
  import { getWatermark } from "@/network/watermask";
  import { request_joinedProjects } from "@/network/personal/index.js";
  
  export default {
    data() {
      return {};
    },
    mounted() {
      const state = this.$route.query.state;
      const authCode = this.$route.query.code;
      let href = "";
      // 微信登录接口
      weChatLogin(authCode).then((res) => {
        if (res.loginSuccessDto) {
          setToken(res.loginSuccessDto.token);
          this.SET_EMAIL(res.loginSuccessDto.userInfo.email);
          this.SET_TOKEN(res.loginSuccessDto.token);
          this.SET_USERINFO(res.loginSuccessDto.userInfo);
          const map = {
            zh: "zh-CN",
            en: "en-US",
          };
          setLanguage({
            language: map[localStorage.getItem("lang")] || "zh-CN",
          }).then((res) => {
            request_joinedProjects()
              .then((res) => {
                this.$store.commit("set_joined_projects", res);
                this.set_release_obj(res[0].projectId);
                sessionSave("p_id", res[0].projectId);
                const backURL = sessionRead("backURL");
                  if (
                    backURL === "null" ||
                    backURL === "" ||
                    typeof backURL === "undefined"
                  ) {
                    this.$router.push({ path: `/home` });
                  } else {
                    sessionRemove("backURL");
                    this.$router.push({ path: backURL });
                  }
                getWatermark().then((res) => {
                  this.SET_WATERMASK({
                    waterMaskSwitch: res.switchFlag ? true : false,
                    waterMaskValue: res.content ? res.content : "",
                  })
                  localStorage.removeItem("thirdparty");
                });
              })
          });
        } else {
          // 将信息存储在 localStorage 中
          localStorage.setItem(
            "weChatUserInfoDto",
            JSON.stringify(res.weChatUserInfoDto)
          );
          if (state) {
              href = `${config.authentication_url}login/thirdparty?inviteMark=${state}`;
          } else {
              href = `${config.authentication_url}login/thirdparty`;
          }
          window.open(href, "_self");
        }
      });
    },
    methods: {
      ...mapMutations([
        "SET_EMAIL",
        "SET_TOKEN",
        "SET_USERINFO",
        "SET_WATERMASK",
      ]),
      ...mapActions([
        "setTestPlan",
        "setVersion",
        "setSprint",
        "setBaseLine",
        "setUser",
        "setExecutionStatus",
        "setFileType",
        "setProjectUserList",
        "setStatus",
      ]),
      set_release_obj(pid) {
        let i = pid ? pid : this.get_pid();
        this.setTestPlan(i);
        this.setVersion(i);
        this.setSprint(i);
        this.setBaseLine(i);
        this.setExecutionStatus(i);
        this.setFileType(i);
        this.setProjectUserList(i);
        this.setStatus(i);
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  .hide_mask {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    left: 0;
    top: 0;
  }
  </style>
  